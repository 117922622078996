<template>
    <div class="ui grid">
        <div class="row centered">
            <div class="eight wide column">
                <h1 class="dossier-welcome text-capitalize">Bonjour {{current_user.nom}}</h1>
            </div>
        </div>
        <div class="row centered">
            <div class="eight wide column">
                <h2 class="dossier-section-title">ESPACE De réinscription</h2>
            </div>
        </div>
        <div class="row centered">
            <div class="twelve wide centered column">
                <div class="dossier-upload-container">
                    <template v-if="!isStudentReInscrit">
                        <div class="submited-title"> Bienvenue !</div>
                        <div class="submited-title">dans votre espace de réinscription</div>
                        <div class="dossier-upload-body">

                            <div style="margin-bottom: 30px">
                                <p class="text-blue text-bold">Veuillez téléverser les scans des documents nécessaires
                                    pour
                                    votre réinscription
                                    <small> ( * seul le format PDF est accepté )</small>
                                </p>

                                <p class="text-bold" style="color: #A16207">
                                    Télécharger le modèle de l'état d'avancement

                                    <small
                                        @click="$store.openDocument('/private/resources/models/etat_avancement_doctorat.docx')"
                                        style="text-decoration: underline; cursor: pointer"> cliquez ici
                                    </small>
                                </p>

                            </div>


                            <upload-item v-for="file in staticFiles" :file-name="file.fileName"
                                         :message="file.message"
                                         :file-type="file.fileType" :key="file.fileType"
                                         :removableFile="true"
                                         :reinscription="true"
                                         @fileRemoved="onFileRemoved"
                                         @uploadCompleted="onUploadCompleted"
                            />

                        </div>
                        <div class="dossier-upload-footer" style="justify-content: end;">
                            <button class="upload-submit" @click="submitAttachments">
                                Soumettre
                            </button>
                        </div>
                    </template>

                    <template v-else>
                        <div class="submited-title"> Merci !</div>
                        <div class="submited-title">Votre demande de reinscription a éte Bien envoyer</div>
                        <p class="submited-message">
                            Vous pouvez suivre l’état de traitement
                            <br/> de votre demande via la page de suivi
                        </p>
                        <div class="flex">
                            <button class="submited-button mt-2 mx-auto"
                                    @click="$router.push({name: 'suivi-reinscription'})">
                                Suivre l’etat de demande
                            </button>

                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import uploadItem from "../components/uploadItem";

export default {
        components: {
            uploadItem
        },
        data() {
            return {
                isBusy: false,
                isSubmited: false,
                uploadedFiles: [],
            }
        },
        computed: {
            current_user() {
                return this.$store.state.current_user
            },
            dossier() {
                return this.$store.getters.dossier
            },
            isStudentReInscrit() {
                return this.dossier?.reinscription && !_.isEmpty(this.dossier.reinscription)
            },
            staticFiles() {
                const staticFiles = JSON.parse(JSON.stringify(this.$SHARED.consts.reinscriptionStaticFiles))
                let indexToRemove = this.current_user.salarie === 'oui' ? 4 : 3

                staticFiles.splice(indexToRemove, 1)

                return staticFiles
            }
        },
        methods: {
            addReinscriptionProcess() {

                let dossier_ = this.dossier

                let data = {
                    dossier_id: dossier_._id,
                    process: 'reinscription'
                }

                if (dossier_.reinscription && _.isEmpty(dossier_.reinscription)) {

                    this.$store.dispatchAsync(this.$SHARED.services.dossier.addProcess, data).then(ar => {
                        this.$toast.success(this.$SHARED.messages.attachment.upload_succeeded)
                        //this.$store.dispatch("get_dossier")
                        log("dossier returned is : ", ar)
                        this.$store.dispatch("get_dossier")
                        //this.$router.push({name: 'suivi-reinscription'})
                    }, code => {
                        this.$toast.error(this.$SHARED.messages[code])

                    }).finally(() => {
                        this.isBusy = false
                    })

                } else {
                    this.$router.push({name: 'suivi-reinscription'})
                    this.isBusy = false
                }

            },
            onUploadCompleted(data) {
                console.log("`onUploadCompleted`")
                this.uploadedFiles.push(data)
            },
            onFileRemoved(filePath) {
                _.remove(this.uploadedFiles, item => item.path === filePath)
            },
            submitAttachments() {
                console.log("`submitAttachments`")

                if (this.isBusy) return

                if (!this.controlFiles()){
                    this.$toast.error("Veuillez télécharger tous les documents de votre candidature")
                    return;
                }

                this.isBusy = true

                const data = {
                    process: this.$SHARED.process.reinscription,
                    attachements: this.uploadedFiles
                }
                let dossier_ = this.dossier


                if (dossier_.reinscription && _.isEmpty(dossier_.reinscription)) {
                    data.addProccess = true;
                    data.id_dossier = dossier_._id;

                }

                this.$store.dispatchAsync(this.$SHARED.services.attachement.create, data).then(() => {
                    this.$store.dispatch("get_dossier")
                    this.uploadedFiles = []
                }, code => {
                    this.$toast.error(this.$SHARED.messages[code])

                }).finally(() => {
                    this.isBusy = false
                })

            },
            controlFiles(){
                const requiredFiles = ["reçu_reinscription", "rapport_activite", "etat_avancement"]
                this.current_user.salarie === 'non' && requiredFiles.push("attestation_non_travail")

                const uploadedFiles = this.uploadedFiles.map(item => item.type)

                return requiredFiles.every(item => uploadedFiles.includes(item))

            }
        }
    }
</script>

<style scoped>
    .dossier-welcome {
        font-weight: 300;
        font-size: 4em;
        color: var(--main-color-blue);
        text-align: center;
        line-height: 1;
        padding-bottom: 0.35em;
        border-bottom: 2px solid var(--main-color-blue);
    }

    .dossier-section-title {
        color: var(--main-color-blue);
        text-align: center;
        font-weight: 800;
        font-size: 2.25em;
    }

    .dossier-upload-container {
        background-color: white;
        padding: 2em;

        box-shadow: 0px 0px 33px -8px rgba(0, 0, 0, 0.25);
        border-radius: 9px;
    }

    .dossier-upload-body {
        /* min-height: 300px; */
    }

    .dossier-upload-footer {
        margin-top: 1em;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .file-upload label {
        border-radius: 5px;
        color: #fff;
        background-color: var(--main-color-blue);
        display: inline-block;
        padding: 0.5em 1em;
        cursor: pointer;
    }

    .file-upload input[type=file] {
        display: none;
    }

    .upload-submit {
        border: none;
        border-radius: 5px;
        color: #fff;
        text-transform: uppercase;
        font-weight: 500;
        background-color: var(--main-color-green);
        padding: 0.75em 1em;
        cursor: pointer;
    }

    .submited-title {
        font-weight: 700;
        font-size: 2.25em;
        text-transform: uppercase;
        line-height: 1.5;
        text-align: center;
        color: var(--main-color-orange);
    }

    .submited-message {
        font-weight: 400;
        font-size: 2.125em;
        /* text-transform: uppercase; */
        line-height: 1.5;
        text-align: center;
        color: var(--main-color-blue);
    }

    .submited-button {
        border: none;
        border-radius: 5px;
        color: #fff;
        text-transform: uppercase;
        font-weight: 500;
        background-color: var(--main-color-blue);
        padding: 0.75em 1em;
        cursor: pointer;
    }
</style>
